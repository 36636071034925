import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import { Flex } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useExpandSideNav } from '@atlassian/navigation-system/layout/side-nav';

import { OnboardingNudge } from '@confluence/onboarding-nudge-tooltip';
import { useSetOnboardingState } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { HOME } from '@confluence/named-routes';
import { ErrorDisplay } from '@confluence/error-boundary';
import { useChoreographerAssets } from '@confluence/choreographer-services';

import { HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS } from '../homeOnboardingTakeoverV2OnboardingStateKeys';
import { useIsEligibleForNthUserHomeOnboardingTakeoverV2HomeNudge } from '../hooks/useIsEligibleForNthUserHomeOnboardingTakeoverV2HomeNudge';

const i18n = defineMessages({
	homeSpotlightHeader: {
		id: 'experiment-home-onboarding-takeover-v2.nth-user-home-nudge.header',
		defaultMessage: 'Get started with Confluence',
		description: 'Message that gets displayed in Spotlight header',
	},
	homeSpotlightText: {
		id: 'experiment-home-onboarding-takeover-v2.nth-user-home-nudge.text',
		defaultMessage: 'Quickly learn about Confluence and become a Confluence expert',
		description: 'Message that gets displayed in spotlight message',
	},
	homeSpotlightCloseButtonText: {
		id: 'experiment-home-onboarding-takeover-v2.nth-user-home-nudge.button-dismiss',
		defaultMessage: 'Dismiss',
		description: 'Close button that gets displayed in spotlight message',
	},
	homeSpotlightTryItButtonText: {
		id: 'experiment-home-onboarding-takeover-v2.nth-user-home-nudge.button-try-it',
		defaultMessage: 'Try it',
		description: 'Try it button that gets displayed in spotlight message',
	},
});

const NudgeFixer: FC = ({ children }: { children?: React.ReactNode }) => {
	return (
		<Flex direction="column" justifyContent="center">
			{children}
		</Flex>
	);
};

type NthUserHomeOnboardingTakeoverV2HomeNudgeProps = {
	position?: 'bottom-start' | 'right-start';
	children?: React.ReactNode;
};

export const NthUserHomeOnboardingTakeoverV2HomeNudge: FC<
	NthUserHomeOnboardingTakeoverV2HomeNudgeProps
> = ({ children, position = 'bottom-start' }) => {
	const [isHomeNudgeHidden, setIsHomeNudgeHidden] = useState(true);
	const { formatMessage } = useIntl();
	const { setOnboardingState } = useSetOnboardingState();

	const { push } = useRouteActions();
	// This is the onboarding state hidden
	const { isEligibleForHomeNudge, isOnHome, error } =
		useIsEligibleForNthUserHomeOnboardingTakeoverV2HomeNudge();
	const wasEligibleForOnboardingNudgeRef = useRef(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { useChoreographer } = useChoreographerAssets();
	const expandSideNav = useExpandSideNav();

	const homeNudgeChoreographer = useChoreographer(
		'nth-user-home-onboarding-takeover-v2-home-nudge',
		{
			start: () => setIsHomeNudgeHidden(false),
			stop: () => setIsHomeNudgeHidden(true),
		},
	);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				action: 'viewed',
				name: 'nthUserHomeOnboardingTakeoverV2HomeNudge',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const handleDismiss = () => {
		setIsHomeNudgeHidden(true);
		void homeNudgeChoreographer.stopMessage();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'nthUserHomeOnboardingTakeoverV2HomeNudgeDismiss',
				source: 'onboarding',
			},
		}).fire();
	};

	const handleGoHome = () => {
		push(HOME.toUrl());
		void homeNudgeChoreographer.stopMessage();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'nthUserHomeOnboardingTakeoverV2HomeNudgeGoHome',
				source: 'onboarding',
			},
		}).fire();
	};

	useEffect(() => {
		if (isEligibleForHomeNudge && isHomeNudgeHidden && !wasEligibleForOnboardingNudgeRef.current) {
			void setOnboardingState({
				key: HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS.hasSeenHomeNudge,
				value: 'true',
			});
			void homeNudgeChoreographer.startMessage();
			expandSideNav();
			wasEligibleForOnboardingNudgeRef.current = true;
		}
	}, [
		isEligibleForHomeNudge,
		isHomeNudgeHidden,
		setOnboardingState,
		homeNudgeChoreographer,
		expandSideNav,
	]);

	const isNudgeHidden =
		!isHomeNudgeHidden && (wasEligibleForOnboardingNudgeRef.current || isEligibleForHomeNudge);

	return (
		<OnboardingNudge
			hidden={!isNudgeHidden || isOnHome}
			setHidden={() => homeNudgeChoreographer.stopMessage()}
			position={position}
			wrapper={NudgeFixer}
			heading={formatMessage(i18n.homeSpotlightHeader)}
			actions={[
				{
					onClick: handleGoHome,
					text: formatMessage(i18n.homeSpotlightTryItButtonText),
				},
				{
					onClick: handleDismiss,
					text: formatMessage(i18n.homeSpotlightCloseButtonText),
				},
			]}
			actionSubjectId="nth-user-home-onboarding-takeover-v2-home-nav-item"
			key="nth-user-home-onboarding-takeover-v2-home-nav-item"
			width={275}
			source="onboarding"
			content={formatMessage(i18n.homeSpotlightText)}
			renderChildrenWhileLoading
		>
			{children}
			{error && <ErrorDisplay error={error} />}
		</OnboardingNudge>
	);
};
