import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

type ItemWithKey = {
	completeKey: string | null;
};

export const filterConnectAppsFromExtensionPoints = <T extends ItemWithKey>(
	location: string[] | string,
	items: T[],
) => {
	const locationsToHideConnectApps = StatsigConfigurations.getStringList(
		'confluence_frontend_hidden_extension_points',
		[],
	);
	const loc = Array.isArray(location) ? location[0] : location;

	if (locationsToHideConnectApps.includes(loc)) {
		items = items.filter(
			(item) => !item?.completeKey?.startsWith('com.atlassian.plugins.atlassian-connect-plugin:'),
		);
	}

	return items;
};
