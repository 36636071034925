import type { ForgeModules } from './ForgeModules';

export const FORGE_MODULE_BYLINE: ForgeModules = 'confluence:contentBylineItem';
export const FORGE_MODULE_CONTEXT_MENU: ForgeModules = 'confluence:contextMenu';
export const FORGE_MODULE_CONTENT_ACTION: ForgeModules = 'confluence:contentAction';
export const FORGE_MODULE_GLOBAL_PAGE: ForgeModules = 'confluence:globalPage';
export const FORGE_MODULE_GLOBAL_SETTINGS: ForgeModules = 'confluence:globalSettings';
export const FORGE_MODULE_HOMEPAGE_FEED: ForgeModules = 'confluence:homepageFeed';
export const FORGE_MODULE_SPACE_PAGE: ForgeModules = 'confluence:spacePage';
export const FORGE_MODULE_SPACE_SETTINGS: ForgeModules = 'confluence:spaceSettings';
export const FORGE_MODULE_CUSTOM_CONTENT: ForgeModules = 'confluence:customContent';
export const FORGE_MODULE_TEST_MODULE: ForgeModules = 'test:module';
export const FORGE_MODULE_XEN_MACRO: ForgeModules = 'xen:macro';

export const forgeGlobalExtensions: ForgeModules[] = [
	FORGE_MODULE_GLOBAL_PAGE,
	FORGE_MODULE_GLOBAL_SETTINGS,
	FORGE_MODULE_HOMEPAGE_FEED,
];

export const forgeSpaceExtensions: ForgeModules[] = [
	FORGE_MODULE_SPACE_PAGE,
	FORGE_MODULE_SPACE_SETTINGS,
	FORGE_MODULE_CUSTOM_CONTENT,
];

export const forgeContentExtensions: ForgeModules[] = [
	FORGE_MODULE_BYLINE,
	FORGE_MODULE_CONTEXT_MENU,
	FORGE_MODULE_CONTENT_ACTION,
	FORGE_MODULE_CUSTOM_CONTENT,
];
