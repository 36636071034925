import type { FC } from 'react';
import React, { memo, useContext, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl-next';

import type { NavigationTheme } from '@atlaskit/atlassian-navigation';
import { AtlassianNavigation } from '@atlaskit/atlassian-navigation';

import { EditionAwareness } from '@confluence/edition-awareness';
import { APP_NAV_GLOBAL_EXPERIENCE, ExperienceSuccess } from '@confluence/experience-tracker';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import type { useWebItemLocationWebItemType } from '@confluence/web-item-location';
import { useWebItemLocation, SYSTEM_HEADER_LEFT } from '@confluence/web-item-location';
import { isBranchDeployFn } from '@confluence/atlassian-internal-help-items';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import {
	PerformanceEnd,
	PERFORMANCE_SUBJECT_navigation,
	PERFORMANCE_SUBJECT_navigationFMP,
} from '@confluence/performance';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { APP_NAVIGATION_METRIC } from '@confluence/browser-metrics/entry-points/app-navigation.metric';
import {
	ConversationAssistantNavBarButton,
	RovoEntitlementSetter,
} from '@confluence/conversation-assistant';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';

import { usePrimaryItems } from './PrimaryItems';
import { useNavigationTheme } from './hooks/useNavigationTheme';
import { ConfluenceProductHome } from './ProductHome';
import { SPACE_DIRECTORY_KEY, PEOPLE_DIRECTORY_KEY } from './featureFlagKeys';
import {
	LazyAppSwitcher,
	LazyNotifications,
	LazySettings,
	LazyHelp,
	LazyProfile,
	LazySearch,
	LazyCreate,
	LazyNotes,
} from './appNavigationLazyItems';
import { NavSkeleton } from './NavSkeleton';

const i18n = defineMessages({
	navRegionLabel: {
		id: 'app-navigation.navigation.region.aria.label',
		defaultMessage: 'Global',
		description: 'A label for the top site navigation region',
	},
	moreLabel: {
		id: 'app-navigation.more.dropdown.label',
		defaultMessage: 'More',
		description:
			'Text on the dropdown that is shown when top nav options are collapsed into one menu',
	},
});

type AppNavigationComponentProps = {
	theme: NavigationTheme | undefined;
	homepageUri: string;
	homepageTitle: string;
	webItems: useWebItemLocationWebItemType[];
	isLimitedNav: boolean;
};

export type AppNavigationProps = {
	isLimitedNav?: boolean;
};

export type AppNavigationComponentType = FC<AppNavigationComponentProps>;
const EmptyItem = () => null;
const noop = () => {};

const AppNavigationComponent: AppNavigationComponentType = ({
	theme,
	homepageUri,
	homepageTitle,
	webItems,
	isLimitedNav,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { formatMessage } = useIntl();
	const { primaryItems } = usePrimaryItems({
		customHomepage: { homepageUri, homepageTitle },
		webItems,
	});
	const {
		isLoggedIn,
		isRovoEnabled: isRovoEnabledSessionData,
		isRovoLLMEnabled,
		accessStatus,
	} = useSessionData();

	const shouldShowRovo = fg('rovo_use_rovo_llm_enabled_check')
		? isRovoLLMEnabled
		: isRovoEnabledSessionData;

	const isRovoEnabled =
		shouldShowRovo && isLoggedIn && accessStatus !== AccessStatus.EXTERNAL_COLLABORATOR_ACCESS;

	const isNotesEnabled = isLoggedIn && fg('confluence_notes');

	/* eslint-disable react/no-unstable-nested-components, react/function-component-definition */
	const RenderNotifications = useMemo(
		() => () => (
			<>
				{isRovoEnabled && <ConversationAssistantNavBarButton />}
				<RovoEntitlementSetter isRovoEnabled={isRovoEnabled} />
				{isNotesEnabled && <LazyNotes />}
				<LazyNotifications />
			</>
		),
		[isRovoEnabled, isNotesEnabled],
	);

	const RenderSearch = useMemo(
		() => () => (
			<>
				<EditionAwareness />
				<LazySearch theme={theme && theme.mode.search} />
			</>
		),
		[theme],
	);
	/* eslint-enable react/no-unstable-nested-components, react/function-component-definition */

	const RenderMoreLabel = useMemo(() => <FormattedMessage {...i18n.moreLabel} />, []);

	return (
		<>
			<div data-vc="app-navigation-base" {...ssrPlaceholderIdProp}>
				<AtlassianNavigation
					moreLabel={RenderMoreLabel}
					renderAppSwitcher={LazyAppSwitcher}
					renderCreate={isLimitedNav ? EmptyItem : LazyCreate}
					renderHelp={LazyHelp}
					renderNotifications={isLimitedNav ? EmptyItem : RenderNotifications}
					renderProfile={LazyProfile}
					renderSettings={isLimitedNav ? EmptyItem : LazySettings}
					primaryItems={isLimitedNav ? [] : primaryItems}
					renderProductHome={ConfluenceProductHome}
					renderSearch={isLimitedNav ? EmptyItem : RenderSearch}
					theme={theme}
					label={formatMessage(i18n.navRegionLabel)}
				/>
			</div>
			<ExperienceSuccess name={APP_NAV_GLOBAL_EXPERIENCE} attributes={{ navVersion: '3' }} />
			<PageSegmentLoadEnd
				metric={APP_NAVIGATION_METRIC}
				customData={{
					isAnonymous: !isLoggedIn,
				}}
			/>
			<PerformanceEnd
				subject={PERFORMANCE_SUBJECT_navigation}
				subjectId="NavigationLoading"
				includeFeatureFlags
				details={nav3Details}
			/>
			<PerformanceEnd
				subject={PERFORMANCE_SUBJECT_navigationFMP}
				subjectId="NavigationFMP"
				includeFeatureFlags
				details={nav3Details}
			/>
		</>
	);
};

export const AppNavigation: FC<AppNavigationProps> = memo((props) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { isLimitedNav = false } = props;
	const { loading: viewContextLoading, homepageUri, homepageTitle } = useContext(SPAViewContext);
	const { loading: themeLoading, theme } = useNavigationTheme();
	const resetStickySearchRef = useRef<() => void>(noop);
	const profileMenuButtonRef = useRef<HTMLButtonElement | null>(null);
	const primaryDropdownItemRef = useRef<HTMLButtonElement | null>(null);
	const createContentPopoverButtonRef = useRef<HTMLButtonElement | null>(null);
	const { loading: webItemsLoading, webItems } = useWebItemLocation({
		location: SYSTEM_HEADER_LEFT,
		allowedWebItems: [SPACE_DIRECTORY_KEY, PEOPLE_DIRECTORY_KEY],
	});

	const appNavigationContextValue = useMemo(
		() => ({
			isBranchDeploy: isBranchDeployFn(),
			theme,
			resetStickySearchRef,
			profileMenuButtonRef,
			primaryDropdownItemRef,
			createContentPopoverButtonRef,
		}),
		[
			theme,
			resetStickySearchRef,
			profileMenuButtonRef,
			primaryDropdownItemRef,
			createContentPopoverButtonRef,
		],
	);

	return (
		<AppNavigationContext.Provider value={appNavigationContextValue}>
			{viewContextLoading || themeLoading || webItemsLoading ? (
				<NavSkeleton />
			) : (
				<AppNavigationComponent
					theme={theme}
					homepageUri={homepageUri}
					homepageTitle={homepageTitle}
					webItems={webItems}
					isLimitedNav={isLimitedNav}
					{...ssrPlaceholderIdProp}
				/>
			)}
		</AppNavigationContext.Provider>
	);
});

const nav3Details = { navVersion: '3' };
