export const SPACE_DIRECTORY_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:header-spaces-link';
export const PEOPLE_DIRECTORY_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:header-people-link';
export const ANALYTICS_MODULE_KEY =
	'com.addonengine.analytics__analytics-instance-settings-menu-item-adg3';
export const ANALYTICS_MODERATE_MODULE_KEY =
	'com.addonengine.analytics.moderate__analytics-instance-settings-menu-item-adg3';
export const FIND_NEW_APPS_KEY = 'com.atlassian.confluence.emcee__enduser-discover';
export const AUTOMATION_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:header-automation-link';
