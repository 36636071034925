import React from 'react';
import type { FC } from 'react';

import { useOnboardingTakeoverType } from '../hooks/useOnboardingTakeoverType';

import { AdminHomeOnboardingTakeoverV2HomeNudge } from './AdminHomeOnboardingTakeoverV2HomeNudge';
import { NthUserHomeOnboardingTakeoverV2HomeNudge } from './NthUserHomeOnboardingTakeoverV2HomeNudge';

type HomeOnboardingTakeoverV2HomeNudgeProps = {
	position?: 'bottom-start' | 'right-start';
	children?: React.ReactNode;
};

export const HomeOnboardingTakeoverV2HomeNudge: FC<HomeOnboardingTakeoverV2HomeNudgeProps> = ({
	children,
	position = 'bottom-start',
}) => {
	const takeoverType = useOnboardingTakeoverType();
	if (takeoverType === 'nth-user') {
		return (
			<NthUserHomeOnboardingTakeoverV2HomeNudge position="bottom-start">
				{children}
			</NthUserHomeOnboardingTakeoverV2HomeNudge>
		);
	}
	return (
		<AdminHomeOnboardingTakeoverV2HomeNudge position={position}>
			{children}
		</AdminHomeOnboardingTakeoverV2HomeNudge>
	);
};
