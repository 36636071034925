import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { useRouteName } from '@confluence/route-manager';
import { WIKI, HOME } from '@confluence/named-routes';
import { useIsSpaceGettingStartedTourEligible } from '@confluence/experiment-space-tour';
import { expValEqualsNoExposure } from '@confluence/feature-experiments';

import { HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS } from '../homeOnboardingTakeoverV2OnboardingStateKeys';

export const useIsEligibleForNthUserHomeOnboardingTakeoverV2HomeNudge = ({
	skip = false,
}: {
	skip?: boolean;
} = {}) => {
	const isOnHomeOrWikiRouteArgs = {
		selector: (routeName: string | undefined) => {
			if (!routeName) {
				return false;
			}
			return routeName === WIKI.name || routeName === HOME.name;
		},
	};
	const isOnHome = useRouteName(isOnHomeOrWikiRouteArgs);
	const {
		isSpaceGettingStartedTourExperimentCohort,
		loading: isSpaceGettingStartedTourExperimentCohortLoading,
	} = useIsSpaceGettingStartedTourEligible();

	const localSkip =
		skip ||
		isOnHome ||
		!expValEqualsNoExposure('cc_onboarding_nth_user_home_takeover', 'cohort', 'experiment') ||
		isSpaceGettingStartedTourExperimentCohortLoading ||
		isSpaceGettingStartedTourExperimentCohort;

	const { data, loading, error } = useGetOnboardingState(
		Object.values(HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS),
		localSkip,
	);

	const { hasSeenHomeNudge, takeoverSeenAt, hasDismissedTakeover } = deserializeState(data);

	if (localSkip || loading || error) {
		return {
			isEligibleForHomeNudge: false,
			isOnHome,
			loading,
			error,
		};
	}

	return {
		isEligibleForHomeNudge: Boolean(
			!hasSeenHomeNudge &&
				!takeoverSeenAt &&
				!hasDismissedTakeover &&
				!isSpaceGettingStartedTourExperimentCohortLoading &&
				!isSpaceGettingStartedTourExperimentCohort,
		),
		isOnHome,
		loading,
		error,
	};
};
