import { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { SPAViewContext } from '@confluence/spa-view-context';

export function useCompanyHubName(): string {
	let { companyHubName } = useContext(SPAViewContext);
	const intl = useIntl();

	if (!companyHubName) {
		companyHubName = intl.formatMessage(messages.defaultCompanyHubName);
	}

	return companyHubName;
}

// eslint-disable-next-line check-i18n-id-prefix/i18n-id-prefix-with-package-name-defineMessages -- Moved from confluence/next/packages/app-navigation/src/Nav3/PrimaryItems/CompanyHubItem.tsx
const messages = defineMessages({
	defaultCompanyHubName: {
		id: 'app-navigation.company-hub.link',
		description: 'name of the fallback name to the company hub page',
		defaultMessage: 'Company hub',
	},
});
