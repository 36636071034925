import type { FC } from 'react';
import React, { useCallback, memo } from 'react';
import { defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { APP_NAV_SPACES_DROPDOWN_EXPERIENCE } from '@confluence/experience-tracker';
import { SSRMouseEventWrapper, SSR_NAV_SPACES_BUTTON_METRIC } from '@confluence/browser-metrics';
import { SPACE_DIRECTORY } from '@confluence/named-routes';
import { preloadSpacesList } from '@confluence/space-utils/entry-points/preloadSpacesList';
import { SpacesDropdown } from '@confluence/spaces-dropdown';
import {
	useRouteDataRef,
	createSingleQueryParamHook,
	useRouteName,
} from '@confluence/route-manager';
import { useSessionData } from '@confluence/session-data';
import { usePageSpaceKey } from '@confluence/page-context';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { TopNavItemNudgeWrappable } from '@confluence/onboarding-hover-nudge/entry-points/BorderNudge';
import { HOVER_TARGET } from '@confluence/onboarding-hover-nudge/entry-points/constants/HoverTarget';
import { useOnboardingNewHomeTakeoverEnabled } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingNewHomeTakeover';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import type { PrimaryItemProps } from './PrimaryItem';
import { PrimaryDropdownItem } from './PrimaryDropdownItem';
import type { ClosePopup } from './PrimaryDropdownItem';

const i18n = defineMessages({
	spacesButton: {
		id: 'app-navigation.spaces.link',
		defaultMessage: 'Spaces',
		description: 'Label for the spaces dropdown',
	},
});

const useLazyOnClick = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireSpacesClickedAnalytics,
);

const useModeParameter = createSingleQueryParamHook('mode');

export const SpacesItem: FC<PrimaryItemProps> = memo(({ testId, pendoId }) => {
	const routeDataRef = useRouteDataRef();
	const modeParameter = useModeParameter();
	const [spaceKey] = usePageSpaceKey();
	// @ts-ignore FIXME: `spaceKey` can be `undefined` here, and needs proper handling
	const selectedSpaceKey: string = spaceKey;
	const { isLicensed } = useSessionData();
	const { isEnabled: isOnboardingNewHomeTakeoverEnabled } = useOnboardingNewHomeTakeoverEnabled();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClick = useLazyOnClick(createAnalyticsEvent, routeDataRef);

	const onHover = useCallback(() => {
		void SpacesDropdown.preload();
		void preloadSpacesList({
			isLicensed,
			selectedSpaceKey,
		});
	}, [isLicensed, selectedSpaceKey]);

	const isNav4Enabled = useIsNav4Enabled();
	const content: FC<ClosePopup> = ({ closePopup }) => (
		<SpacesDropdown closePopup={closePopup} isNav4Enabled={isNav4Enabled} />
	);

	const isOnSpaceRouteArgs = {
		selector: (routeName: string | undefined) => {
			if (!routeName) {
				return false;
			}
			return routeName === SPACE_DIRECTORY.name;
		},
	};
	const isOnSpaceRoute = useRouteName(isOnSpaceRouteArgs);
	const isHighlighted =
		!isCompanyHubSpaceKey(spaceKey) && isOnSpaceRoute && !modeParameter?.includes('global');

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_SPACES_BUTTON_METRIC}>
			<TopNavItemNudgeWrappable
				target={HOVER_TARGET.SPACES}
				shouldWrap={isOnboardingNewHomeTakeoverEnabled}
			>
				<div>
					<SpotlightTarget name="app-navigation-spaces-dropdown">
						<PrimaryDropdownItem
							triggerId="app-navigation-spaces-dropdown-trigger"
							testId={testId}
							i18n={i18n.spacesButton}
							content={content}
							collapsedTestId="app-navigation-collapsed-spaces"
							onOpen={() => onClick(true)}
							onClose={() => onClick(false)}
							onHover={onHover}
							isHighlighted={isHighlighted}
							experienceName={APP_NAV_SPACES_DROPDOWN_EXPERIENCE}
							pendoId={pendoId}
						/>
					</SpotlightTarget>
				</div>
			</TopNavItemNudgeWrappable>
		</SSRMouseEventWrapper>
	);
});
