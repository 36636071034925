import { type MessageDescriptor } from 'react-intl-next';

import { fg } from '@confluence/feature-gating';

// We use this type to merge generics of the two i18n objects, maintaining the exact type specifity matching the output of `defineMessages`.
//   With this, the return type of this function is e.g. `{ header: { id: string; defaultMessage: string; description: string; }; }`
//   Without this, the return type of the function is `Record<string, MessageDescriptor>`.
//   MessageDescriptor is too loose of a return type, it would cause implementations that drill into the i18n object's properties to throw a type error due to not being sure if the type is undefined, a string, or something else.
type Merge<T, U> = {
	[K in keyof T | keyof U]: K extends keyof U ? U[K] : K extends keyof T ? T[K] : never;
};
type MessageObject = Record<string, MessageDescriptor>;

// Note: The merged i18n object will only support the proxy functions implemented here. For example, setting values on the object isn't supported because we're not supporting the proxy's `set` handler function.
export const mergeLiveDocI18n = <T extends MessageObject, U extends MessageObject>(
	i18nBase: T,
	i18nLiveDocs: U,
): Merge<T, U> => {
	const initializeData = (targetObj: any) => {
		targetObj._data = fg('confluence_live_pages_open_beta_trait_opted_in')
			? { ...i18nBase, ...i18nLiveDocs }
			: i18nBase;
		targetObj._initialized = true;
	};

	return new Proxy({} as any, {
		get: (targetObj, prop) => {
			// Ensures retrieval of properties on i18n object use i18n keys instead of targetObj keys
			if (!targetObj._initialized) initializeData(targetObj);

			return targetObj._data[prop];
		},

		ownKeys: (targetObj) => {
			// ensures `.keys` uses the i18n keys instead of targetObj keys
			if (!targetObj._initialized) initializeData(targetObj);

			return Reflect.ownKeys(targetObj._data);
		},

		has: (targetObj, prop) => {
			// Ensures for...in uses the i18n keys instead of targetObj keys
			if (!targetObj._initialized) initializeData(targetObj);

			return prop in targetObj._data;
		},

		getOwnPropertyDescriptor: (targetObj, prop) => {
			// Ensures i18n props are enumerable, required for ownKeys/etc to work
			if (!targetObj._initialized) initializeData(targetObj);

			const descriptor = Object.getOwnPropertyDescriptor(targetObj._data, prop);
			return descriptor ? { ...descriptor, enumerable: true } : undefined;
		},
	});
};
