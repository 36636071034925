import { useCallback } from 'react';
import { createStore, createHook, createActionsHook } from 'react-sweet-state';

import type { MenuId } from './useMenuItems';

type FlyoutState = {
	openFlyoutId: MenuId | null;
};

const initialState: FlyoutState = {
	openFlyoutId: null, // Keeps track of the currently open flyout by its menuId
};

export const FlyoutStore = createStore({
	name: 'FlyoutStore',
	initialState,
	actions: {
		openFlyout:
			(menuId: MenuId) =>
			({ setState }) => {
				setState({ openFlyoutId: menuId });
			},
		closeFlyout:
			(menuId: MenuId | null = null) =>
			({ setState, getState }) => {
				if (!menuId || menuId === getState().openFlyoutId) {
					setState({ openFlyoutId: null });
				}
			},
	},
});

export const useIsFlyoutOpen = createHook(FlyoutStore, {
	selector: (state: FlyoutState, menuId: MenuId | undefined) => state.openFlyoutId === menuId,
});

export const useFlyout = (menuId: MenuId) => {
	const [isFlyoutOpen, flyoutActions] = useIsFlyoutOpen(menuId);
	const openFlyout = useCallback(() => flyoutActions.openFlyout(menuId), [flyoutActions, menuId]);
	const closeFlyout = useCallback(() => flyoutActions.closeFlyout(menuId), [flyoutActions, menuId]);

	return { isFlyoutOpen, openFlyout, closeFlyout };
};

export const useFlyoutActions = createActionsHook(FlyoutStore);
