import React, { Fragment } from 'react';
import { styled } from '@compiled/react';

import Spinner from '@atlaskit/spinner/spinner';
import { token } from '@atlaskit/tokens';

const zIndexBlanket = 500; // layers.blanket()
// + 1 to move spinner above the blanket
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Content = styled.div({
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	zIndex: zIndexBlanket + 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Blanket = styled.div({
	zIndex: zIndexBlanket,
	height: '100%',
	width: '100%',
	position: 'fixed',
	left: 0,
	top: 0,
	backgroundColor: token('elevation.surface'),
	opacity: 0.54,
	transition: 'opacity 0.2s',
	transitionDelay: '0.1s',
});

export const FullPageLoadingScreen = () => (
	<Fragment>
		<Content>
			<Spinner interactionName="full-page-loading-screen" size={26} />
		</Content>
		<Blanket />
	</Fragment>
);
