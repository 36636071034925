import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { atlassianTheme, generateTheme } from '@atlaskit/atlassian-navigation';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import { AppNavigationUnifiedQuery } from '../AppNavigationUnifiedQuery.graphql';
import type { AppNavigationUnifiedQuery as AppNavigationUnifiedQueryType } from '../__types__/AppNavigationUnifiedQuery';
import { useIsDarkMode } from '../../theme/useIsDarkMode';
import { getCustomTheme } from '../../theme/getCustomTheme';

export const useNavigationTheme = () => {
	const { data, loading, error } = useQuery<AppNavigationUnifiedQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		AppNavigationUnifiedQuery,
	);

	if (error && isUnauthorizedError(error)) {
		markErrorAsHandled(error);
	}

	const isDarkMode = useIsDarkMode();

	const theme = useMemo(() => {
		return loading ? atlassianTheme : generateValidTheme(data, isDarkMode);
	}, [loading, data, isDarkMode]);

	return { loading, theme };
};

const generateValidTheme = (
	data: AppNavigationUnifiedQueryType | undefined,
	isDarkMode: boolean,
) => {
	const customTheme = getCustomTheme({
		backgroundColorRaw: data?.lookAndFeel?.custom?.horizontalHeader?.backgroundColor,
		highlightColorRaw:
			data?.lookAndFeel?.custom?.horizontalHeader?.primaryNavigation?.highlightColor,
		isDarkMode,
		isNav4Enabled: false,
	});

	if (!customTheme) {
		return atlassianTheme;
	}

	return generateTheme({
		name: 'theme',
		...customTheme,
	});
};
