import React, { memo, useContext, useState, useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import {
	SSRMouseEventWrapper,
	SSR_NAV_COMPANY_HUB_BUTTON_METRIC,
} from '@confluence/browser-metrics';
import { withFlags, type WithFlagsProps } from '@confluence/flags';
import { SPAViewContext } from '@confluence/spa-view-context';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { usePageSpaceKey } from '@confluence/page-context';
import {
	FrontCoverStateEnum,
	isCompanyHubSpaceKey,
} from '@confluence/route-manager/entry-points/companyHubUtils';
import type { companyHubItemCallbackArgs } from '@confluence/company-hub-utils/entry-points/companyHubItemCallback';
import { useCompanyHubName } from '@confluence/company-hub-utils/entry-points/useCompanyHubName';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { fg } from '@confluence/feature-gating';
import {
	ADMIN_COMPANY_HUB_PERMISSIONS,
	COMPANY_HUB,
	COMPANY_HUB_ANALYTICS,
	COMPANY_HUB_SETTINGS,
} from '@confluence/named-routes';
import { ExperienceTrackerContext } from '@confluence/experience-tracker';
import { FullPageLoadingScreen } from '@confluence/full-page-loading-screen';

const lazyOnClick = async (args: companyHubItemCallbackArgs) => {
	const { companyHubItemCallback } = await import(
		/* webpackChunkName: "loadable-companyHubItemCallback" */ '@confluence/company-hub-utils/entry-points/companyHubItemCallback'
	);
	await companyHubItemCallback(args);
};

// While app-navigation in nav v3 and side-navigation in nav v4 both have CompanyHubItem, both are still maintained, and they're more common and different, AbstractCompanyHubItem contains the common parts.
export const AbstractCompanyHubItem = withFlags(
	memo(({ children, flags, navVersion }: AbstractCompanyHubItemProps & WithFlagsProps) => {
		const { frontCoverState } = useContext(SPAViewContext);
		const companyHubName = useCompanyHubName();
		const { match, push } = useContext(RoutesContext);
		const [spaceKey] = usePageSpaceKey();
		const [isLoading, setIsLoading] = useState(false);

		const mayBeFirstTimeVisit =
			frontCoverState === FrontCoverStateEnum.UNSET && !fg('company-hub-pseudo-app');
		const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
			skip: !mayBeFirstTimeVisit,
		});
		// The link/namedRoute of this PrimaryItem not being ready means that the
		// space doesn't exist yet and is to be created first:
		const namedRoute = mayBeFirstTimeVisit && !shouldShowPremiumUpsell ? undefined : COMPANY_HUB;

		const routeName = match?.name;

		const { createAnalyticsEvent } = useAnalyticsEvents();
		const experienceTracker = useContext(ExperienceTrackerContext);
		const onClick = useCallback(async () => {
			const isFirstTimeVisit = !namedRoute;
			const args: companyHubItemCallbackArgs = {
				createAnalyticsEvent,
				experienceTracker,
				flags,
				isFirstTimeVisit,
				push,
				routeName,
				navVersion,
			};
			if (isFirstTimeVisit) {
				// There's no route to navigate to, it needs to be created first. This
				// may take a noticeably long time so a loading state visible to the
				// user is necessary:
				setIsLoading(true);
				try {
					await lazyOnClick(args);
				} catch {
					// Not much can be done about a JS bundle failing to load even if the
					// failure were reported.
				} finally {
					setIsLoading(false);
				}
			} else {
				// There's a route to navigate to already, what's left is processing not
				// visible to the user. So there's no need for a loading state visible
				// to the user here:
				void lazyOnClick(args);
			}
		}, [createAnalyticsEvent, experienceTracker, flags, namedRoute, navVersion, push, routeName]);

		const isSelected =
			routeName === COMPANY_HUB.name ||
			(routeName !== ADMIN_COMPANY_HUB_PERMISSIONS.name &&
				routeName !== COMPANY_HUB_ANALYTICS.name &&
				routeName !== COMPANY_HUB_SETTINGS.name &&
				isCompanyHubSpaceKey(spaceKey));

		return (
			<SSRMouseEventWrapper metricName={SSR_NAV_COMPANY_HUB_BUTTON_METRIC}>
				{children({ companyHubName, isSelected, namedRoute, onClick })}
				{isLoading ? <FullPageLoadingScreen /> : null}
			</SSRMouseEventWrapper>
		);
	}),
);

type AbstractCompanyHubItemProps = {
	navVersion: '3' | '4';
	children: (props: {
		companyHubName: string;
		isSelected: boolean;
		namedRoute: undefined | typeof COMPANY_HUB;
		onClick(): void;
	}) => JSX.Element;
};
